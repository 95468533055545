// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// Custom style for login Page
@import "login";

// Custom style for m card Page
@import "m-card";

// Custom style for statement bonus Page
@import "statement";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

// Spinkit
$spinkit-spinner-color: $body-color;
@import "~spinkit/scss/spinkit.scss";

// Tree styles
@import "tree_style";

// react bootstrap table next
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';


.bonus-statement {
  td {
    padding-top: 6px;
    padding-bottom: 6px;

  }
}

.bg-orange-table {
  background-color: #F6C296;
}

.border-bottom-table {
  border-bottom: 2px solid #626262 !important;
}