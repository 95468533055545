@font-face {
  font-family: "Avenir LT Std Roman";
  src: url("scss/fonts/AvenirLTStd-Roman.otf");
}

@font-face {
  font-family: "Avenir LT Std Heavy";
  src: url("scss/fonts/AvenirLTStd-Heavy.otf");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Avenir LT Std Heavy", Arial;
}

body {
  background: none;
  font-family: "Avenir LT Std Roman", Arial;
}

.text-light {
  font-weight: 300;
}
