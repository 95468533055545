@media (max-width: 466px) {
  .mCard-Container {
    padding: 0px;
  }
  .mCard-header {
    margin-bottom: 30px;
  }
  .mCard-detail {
    padding: 10px;
    margin-bottom: 30px;
  }
  .mCard-info {
    margin-bottom: 20px;
  }
  .mCard-licence {
    margin-bottom: 10px;
  }
}
.Bstar-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  padding-top: 15px;
}

.mCard-Container {
  background-image: url("./assets/img/brand/B Star web final background-01-m-card.jpg");
  border-radius: 15px;
  border: 5px solid #d0cece;
  padding: 20px;
  margin: auto;
  width: 580px;
  max-width: 580px;
  overflow: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.mCard-header {
  margin: auto;
  padding-top: 150px;
  width: 80%;
  height: 200px;
  margin-bottom: 50px;
}

.mCard-foto {
  // margin: auto;
  // width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  z-index: 10;
}

.mCard-foto-display {
  width: 100px;
  height: 100px;
}

.mCard-foto-input {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 15px;
  border: 5px dashed #d0cece;
  padding: 8px;
  align-items: center;
}

.mCard-profil-foto {
  width: 100px;
  height: 100px;
}

.mCard-name {
  margin: auto;
  margin-top: 70px;
  padding: 10px;
  padding-top: 30px;
  width: 80%;
  // border: 3px solid #c86115;
  // border-radius: 8px;
  // background-color: #d1b666;
  opacity: 0.8;
}

.mCard-detail {
  margin: 150px auto 0;
  padding: 20px;
  width: 95%;
  // border: 3px solid #c86115;
  // border-radius: 5px;
  // background-color: #d1b666;
  opacity: 0.8;
  margin-bottom: 50px;
}

.mCard-info {
  margin: auto;
  width: 100%;
  margin-bottom: 140px;
}

.mCard-licence {
  margin: auto;
  width: 100%;
  height: 100px;
  margin-bottom: 50px;
  padding-top: 20px;
}

.mCard-footer {
  align-items: center;
}

.mCard-logo {
  width: 70px;
  height: 20px;
}

.mCard-logo-oke {
  width: 70px;
  height: 50px;
}

.mCard-btn-row {
  justify-content: space-between;
  margin-bottom: 10px;
}

.tem {
  display: block;
  font-weight: bold;
}

.bold {
  font-weight: bolder;
  font-size: 15px;
  color: black;
}

.font-size-18 {
  font-size: 18px;
}

.tes {
  font-size: 9px;
}

.tel {
  display: block;
  text-decoration: underline;
  font-weight: bolder;
  color: #1c88f6;
}

.Bstar-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.mCard-Container-don {
  background-image: url("./assets/img/brand/B Star web final background-01-m-card.jpg");
  border-radius: 15px;
  border: 5px solid #d0cece;
  padding: 20px;
  margin: auto;
  width: 580px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.mCard-header-don {
  margin: auto;
  padding-top: 150px;
  width: 80%;
  height: 200px;
  margin-bottom: 50px;
}

.mCard-foto-don {
  // margin: auto;
  // width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 100px;
  z-index: 10;
}

.mCard-foto-display-don {
  width: 100px;
  height: 100px;
}

.mCard-foto-input-don {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 15px;
  border: 5px dashed #d0cece;
  padding: 8px;
  align-items: center;
}

.mCard-profil-foto-don {
  width: 100px;
  height: 100px;
}

.mCard-name-don {
  margin: auto;
  margin-top: 70px;
  padding: 10px;
  padding-top: 30px;
  width: 80%;
  // border: 3px solid #c86115;
  // border-radius: 8px;
  // background-color: #d1b666;
  opacity: 0.8;
}

.mCard-detail-don {
  margin: 150px auto 0;
  padding: 20px;
  width: 95%;
  // border: 3px solid #c86115;
  // border-radius: 5px;
  // background-color: #d1b666;
  opacity: 0.8;
  margin-bottom: 50px;
}

.mCard-info-don {
  margin: auto;
  width: 100%;
  margin-bottom: 140px;
}

.mCard-licence-don {
  margin: auto;
  width: 100%;
  height: 100px;
  margin-bottom: 50px;
  padding-top: 20px;
}

@media print {
  * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important;
  }
}
