.login {
  min-height: 900px;
  overflow: auto;
}
.section-slider {
  // padding: 50px;
  position: relative;
  width: 100%;
  height: 1155px;
  .body-slider {
    padding-top: 150px;
  }
  .footer-slider {
    padding-top: 220px;
  }
  .background-img {
    width: 100%;
    height: 1155px;
  }
  .background-img-mob {
    display: none;
  }
}

.logo-login {
  height: 100px;
  // height: 55px;
}

.container-login {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  top: 0;

  .bstar-logo {
    width: 100%;
    // margin-left: 20%;
  }

  .form-login {
    height: 100%;
    width: 450px;
    margin: 0 auto;
  }
}

.section-login {
  margin-top: 50px;
  padding: 30px;
  border-radius: 5px;
  background: #dddd;
  // .input-radius {
  //   border-radius: 0 15px 15px 0 !important;
  // }
  // .icon-radius {
  //   border-radius: 15px 0 0 15px !important;
  // }
  // .btn-rounded {
  //   border-radius: 20px !important;
  // }
  .icon-bg {
    background-color: #5e5e5e;
  }
  .input-bg {
    background-color: #5e5e5e;

    &::placeholder {
      color: #fff;
    }
  }
  .body-header {
    padding-top: 30px;
  }
  .body-login {
    padding-top: 20px;
  }
  .footer-login {
    margin-top: 50px;
    width: 100%;
    padding-left: 55px;
    padding-right: 55px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
    // justify-content: space-between;

    &--version {
      padding-top: 5px;
      font-size: 12px;
      width: 100%;
      text-align: center;
    }
    &--support {
      text-align: center;
      .logo-support {
        width: 80px;
      }
    }
  }
}

@media (max-width: 768px) {
  .login {
    min-height: 568px;
    overflow: auto;
  }
  .section-slider {
    position: relative;
    width: 100%;
    height: 1155px;

    .background-img-mob {
      display: block;
      width: 100%;
      height: 1155px;
    }

    .background-img {
      display: none;
    }
  }

  .logo-login {
    height: 70px;
    // height: 55px;
  }

  .container-login {
    position: absolute;
    margin: 0 auto;
    width: 100%;
    height: 500px;
    top: 0;

    .bstar-logo {
      width: 90%;
      // margin-left: 20%;
    }
    .form-login {
      height: 100%;
      width: 300px;
      margin: 0 auto;
    }
  }

  .section-login {
    position: relative;
    width: 100%;
    .body-header {
      padding-top: 30px;
    }
    .body-login {
      padding-top: 30px;

      h3 {
        font-size: 18px;
      }

      p {
        font-size: 12px;
      }
    }
    .footer-login {
      margin-top: 50px;
      width: 100%;
      padding-left: 0;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-content: stretch;
      // justify-content: space-between;

      &--version {
        padding-top: 5px;
        font-size: 12px;
        width: 100%;
        text-align: center;
      }
      &--support {
        text-align: center;
      }
      .logo-support {
        width: 60px;
      }
    }
  }
}
