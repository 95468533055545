// Here you can add other styles
@import "fonts";
@import "card";
@import "sidebar";
@import "canvas";
@import "cursor";
@import "pagination";
@import "table";
@import "custom_form";

.app-header {
  background-color: #cbcbcb;
}

.between-top-bottom-padding {
  padding: 10px 0;
}

.hidden {
  display: none;
}

.error{
  color: red;
}

.a-hover {
  color: #f86c6b !important;
}

.a-hover:hover {
  color: #fff !important;
}