.sidebar {
  background: #CBCCCB;
}

.nav {
  background: #e0e0e0;
}

.sidebar .nav-link {
  color: #000000;
  background: #e0e0e0;
}

.sidebar .nav-dropdown.open {
  background: #e0e0e0;
}

.sidebar .nav-dropdown.open .nav-link {
  color: #000000;
}

.sidebar .nav-dropdown.open .nav-link:hover {
  color: #fff;
}

.sidebar .nav-dropdown.open .nav-link.active {
  color: #fff;
}

.sidebar .nav-link .nav-icon {
  color: #000000;
}

.sidebar .nav-link.active {
  background: #ff3300;
}

.sidebar .nav-link.active .nav-icon {
  color: #fff;
}

.sidebar .nav-link:hover {
  background: #ff3300;
}

.sidebar .nav-link:hover {
  background: #ff3300;
}

@media (min-width: 992px) {
  .sidebar-minimized .sidebar .sidebar-minimizer {
    background: #fff;
    width: 40px;
  }

  .sidebar-minimizer:hover {
    color: #24282c;
  }
}
