.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 5px;
  box-shadow: 0 1px 6px 0 rgba(49, 53, 59, 0.12);
}

.card-header {
  padding-top: 0.4rem;
  padding-left: 0.8rem;
  padding-bottom: 0.4rem;
  padding-right: 0.8rem;
  margin-bottom: 0;
  font-size: 20px;
  background-color: red;
  color: white;
  border-bottom: 0px transparent;
}

.header-bonus {
  position: relative;
  left: 30%;
  top: 10%;
}

.periode-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  &-select {
    width: 300px;
  }
}

.card-body {
  flex: 1 1 auto;
  padding-top: 0.8rem;
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
  padding-right: 0.8rem;
}

.card-print {
  position: relative;
  .card-print-header {
    background: #ff3300;
    color: #fff;
    padding-top: 0.2rem;
    padding-left: 0.8rem;
    padding-bottom: 0.2rem;
    padding-right: 0.8rem;
    margin-bottom: 20px;
    font-size: 20px;
  }
  .card-print-body {
    padding-top: 0.2rem;
    padding-left: 0.8rem;
    padding-bottom: 0.2rem;
    padding-right: 0.8rem;
  }
}

.card-invoice {
  position: relative;
  border-radius: 5px;
  border: 1px solid #ddd;
  .card-invoice-header {
    background: #eee;
    border-bottom: 1px solid #ddd;
    padding-top: 0.4rem;
    padding-left: 0.8rem;
    padding-bottom: 0.4rem;
    padding-right: 0.8rem;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .card-invoice-body {
    padding-top: 0.2rem;
    padding-left: 0.8rem;
    padding-bottom: 0.2rem;
    padding-right: 0.8rem;
  }
}

.periode-box-select {
  width: 150px;
}

@media (max-width: 466px) {
  .card-header {
    font-size: 14px;
  }
  .header-bonus {
    font-size: 20px;
    left: 25%;
  }
  .periode-box-select {
    width: 100px;
  }
}
