.border-dark {
  border: 2px solid #434343;
}
.table-bonus, td, th {
  padding: 5px;
}
.bonus-statement {
  min-width: 950px;
  max-width: 1024px;
}
.onepage {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.child {
  font-size: 12px;
}
.p-periode {
  padding: 5px 0
}

.border-all {
  border: 2px solid #434343;
}
.expand:hover {
  cursor: pointer;
}

.minimize:hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  .onepage {
    zoom: 50%;
    display: block;
    overflow: auto;
  }
  .bonus-statement {
    min-width: 550px;
    max-width: 1024px;
    font-size: 10px;
  }
  .main .container-fluid {
    padding: 0 20px;
  }
  .header-bonus {
    position: relative;
    left: 40%;
    top: 10%;
  }
  .child {
    font-size: 10px;
  }
  .p-periode {
    padding: 8px 0
  }
}
